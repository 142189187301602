/* eslint no-console:0 */
// import "../css/client_portal.css"

import "../packs/global.js";

import { Application } from "@hotwired/stimulus"

// import SignaturePad from 'signature_pad/dist/signature_pad.js'
import Signable from '../packs/global/signable.js'
import ConflictCheck from '../packs/global/conflict_check.js'
import Loopable from '../packs/global/loopable.js'
import ConditionalAnswers from '../packs/global/conditional_answer'
import Validation from '../packs/global/validation'
import Tooltip from '../packs/global/tooltip'

import DropdownController from "../packs/common_controllers/dropdown_controller"
import LawpayPaymentFormController from "../packs/common_controllers/lawpay_payment_form_controller.js"
import TimeoutRedirectController from "../packs/common_controllers/timeout_redirect_controller.js"
import CountryStateSelectController from "../packs/common_controllers/country_state_select_controller.js";
import RichtextController from "../packs/common_controllers/richtext_controller.js";
import DropzoneController from "../packs/common_controllers/dropzone_controller"
import VideoPlayerController from "../packs/common_controllers/video_player_controller.js"
window.Stimulus = Application.start()
Stimulus.register("dropdown", DropdownController)
Stimulus.register("lawpay-payment-form", LawpayPaymentFormController)
Stimulus.register("timeout-redirect", TimeoutRedirectController)
Stimulus.register("country-state-select", CountryStateSelectController)
Stimulus.register("richtext", RichtextController)
Stimulus.register("dropzone", DropzoneController)
Stimulus.register("video-player", VideoPlayerController)

window.Signable = Signable;
window.ConflictCheck = ConflictCheck;
window.Loopable = Loopable;
window.ConditionalAnswers = ConditionalAnswers;
window.Validation = Validation;

$(function () {
  Signable();
  ConflictCheck();
  Loopable();
  ConditionalAnswers();
  Validation();
  Tooltip();
});
